<template>
  <div>
    <van-pull-refresh
      v-model="isreFreshLoading"
      :success-text="refreshSuccessText"
      :success-duration="1500"
      @refresh="onRefresh"
    >
      <van-list
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        :error.sync="error"
        error-text="请求失败，点击重新加载"
        @load="onLoad"
      >
        <div class="item" v-for="(item, ind) in list" :key="ind">
          <div class="activeTime">{{ item.end_time }}前截止报名</div>
          <div class="axtiveInfo" @click="detail(item)">
            <div class="user-img">
              <img :src="item.image" alt="" />
            </div>
            <div class="info">
              <div class="info-title">
                <div class="num">{{ item.num_limit }}人</div>
                <div class="txt">{{ item.title }}</div>
              </div>
              <div class="info-time">
                <img src="../../assets/img/time.png" alt="" />
                <div>{{ item.activity_time }}</div>
              </div>
              <div class="info-address">
                <img src="../../assets/img/address.png" alt="" />
                <div class="van-ellipsis">
                  {{ item.event_venue }}
                </div>
              </div>
            </div>
          </div>
          <div class="activeMoney">
            <div class="origin-price">￥{{ item.entrance_fee }}/人</div>
            <img src="../../assets/img/25.png" alt="" />
            <div>
              <span class="vipprice">￥{{ item.vip_entrance_fee }}</span
              >/人
            </div>
          </div>
          <div class="activeApply" v-if="item.status == 3">
            <div class="applyblack" v-if="item.has_fllow == 1">
              你心仪的TA也报名了此活动
              <!--  {{ item.sign_up_count }}人已报名，剩余{{
              item.num_limit - item.sign_up_count
            }}空位 -->
            </div>
            <div v-else></div>
            <div class="btn" @click="apply(item)">立即报名</div>
          </div>
          <div class="activeApply" v-if="item.status == 4">
            <div class="apply"><!-- 36人已报名，剩余14空位 --></div>
            <div class="graybtn">已报名</div>
          </div>
          <div class="activeApply" v-if="item.status == 5">
            <div class="applyblack">人数已满，有人退出可继续报名</div>
            <div class="graybtn">已满员</div>
          </div>
          <div class="activeApply" v-if="item.status == 1">
            <div class="apply"></div>
            <div class="btn" @click="subscribe(item)">预约</div>
          </div>
          <div class="activeApply" v-if="item.status == 2">
            <div class="apply" v-if="item.has_fllow == 1">
              你心仪的TA也预约了此活动
            </div>
            <div v-else></div>
            <div class="graybtn">已预约</div>
          </div>
        </div>
      </van-list>
    </van-pull-refresh>
  </div>
</template>

<script>
import { getActivity, sign_up } from "@/api/activity";
export default {
  props: {
    chanal: {
      type: Object,
      require: true
    }
  },
  data() {
    return {
      list: [],
      page: 1,
      pageSize: 20,
      loading: false, // 控制加载中 loading 状态
      finished: false, // 控制数据加载结束的状态
      error: false, // 控制列表加载失败的提示状态
      isreFreshLoading: false, // 控制下拉刷新的 loading 状态
      refreshSuccessText: "刷新成功", // 下拉刷新成功提示文本
      total: 0
    };
  },
  created() {},
  mounted() {},
  methods: {
    async onLoad() {
      try {
        const res = await getActivity({
          type: this.chanal.type,
          page: this.page,
          pageSize: this.pageSize,
          access_id: sessionStorage.getItem("access_id")
        });
        const { list } = res.data.data;
        this.total = res.data.data.count;
        this.list.push(...list);
        this.loading = false;
        if (this.page * this.pageSize < this.total) {
          this.page++;
        } else {
          this.finished = true;
        }
        console.log(res, "985");
      } catch (err) {
        this.error = true;
        this.loading = false;
      }
    },
    /* 报名 */
    apply(item) {
      if (item.has_description == 0) {
        this.$router.push({
          path: "/personalData"
        });
      }
      if (item.has_description == 1 && item.status == 3) {
        this.$router.push({
          path: "/confirmPay",
          query: {
            id: item.id
          }
        });
      } else {
        this.$toast("请稍后重试");
      }
    },
    /* 预约 */
    async subscribe(item) {
      const res = await sign_up({ a_id: item.id });
      if (res.data.code == 200) {
        item.status = 2;
        this.$toast("预约成功");
      } else if (res.data.code == 80009) {
        return this.$router.push("/personalData");
      } else {
        this.$toast(res.data.message);
      }
      console.log(res);
    },
    detail(item) {
      console.log(item);
      this.$router.push({ path: "/activityDetail", query: { id: item.id } });
    },
    async onRefresh() {
      try {
        const res = await getActivity({
          type: this.chanal.type,
          page: this.page,
          pageSize: this.pageSize,
          access_id: sessionStorage.getItem("access_id")
        });
        console.log(res);
        this.isreFreshLoading = false;
        this.list = [];
        const { list } = res.data.data;
        this.list.unshift(...list);
      } catch (err) {
        console.log(err);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.item {
  box-sizing: border-box;
  width: 708px;
  // height: 473px;
  background-color: #fff;
  border-radius: 14px;
  margin: 21px auto;
  padding: 34px 27px 42px;
  .activeTime {
    font-size: 28px;
    color: #333333;
    margin-bottom: 42px;
  }
  .axtiveInfo {
    display: flex;
    .user-img {
      width: 174px;
      height: 174px;
      border-radius: 14px;
      margin-right: 14px;
      img {
        width: 174px;
        height: 174px;
        border-radius: 14px;
      }
    }
    .info {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      padding: 12px 0;
      .info-title {
        display: flex;
        .num {
          max-width: 140px;
          height: 33px;
          background: #f45b52;
          border-radius: 3px;
          text-align: center;
          line-height: 37px;
          color: #fff;
          padding: 0 5px;
          margin-right: 8px;
        }
        .txt {
          //height: 31px;
          font-size: 33px;
          width: 380px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          font-family: Source Han Sans CN;
          font-weight: 600;
          color: #333333;
        }
      }
      .info-time {
        font-size: 25px;

        font-weight: 400;
        color: #999999;
        display: flex;
        align-items: center;
        img {
          width: 28px;
          height: 28px;
          margin-right: 8px;
        }
      }
      .info-address {
        font-size: 25px;
        font-weight: 400;
        color: #999999;
        display: flex;
        align-items: center;
        width: 460px;
        img {
          width: 28px;
          height: 28px;
          margin-right: 8px;
        }
      }
    }
  }
  .activeMoney {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 21px;
    .origin-price {
      font-size: 21px;
      text-decoration: line-through;
      color: #999999;
    }
    img {
      width: 35px;
      height: 35px;
      margin-left: 13px;
    }
    .vipprice {
      font-size: 42px;
      font-family: Source Han Sans CN;
      font-weight: bold;
      color: #000000;
    }
  }
  .activeApply {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 25px;
    .notice-swipe {
      height: 40px;
      line-height: 40px;
    }
    .van-notice-bar {
      width: 360px;
      padding: 0;
    }
    .btn {
      width: 174px;
      height: 63px;
      background: linear-gradient(-30deg, #f7bc32 0%, #ffd449 98%);
      border-radius: 31px;
      text-align: center;
      line-height: 65px;
      color: #fff;
      font-size: 28px;
    }
    .graybtn {
      width: 174px;
      height: 63px;
      background: #eeeeee;
      border-radius: 31px;
      text-align: center;
      line-height: 65px;
      color: #999999;
      font-size: 28px;
    }
    .apply {
      font-size: 24px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #f45b52;
    }
    /*  .btn {
        width: 174px;
        height: 63px;
        background: linear-gradient(-30deg, #f7bc32 0%, #ffd449 98%);
        border-radius: 31px;
        text-align: center;
        line-height: 65px;
      } */
  }
}
</style>
