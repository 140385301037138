<template>
  <div class="container">
    <van-tabs
      v-model="active"
      sticky
      background="#f2f2f2"
      title-inactive-color="#999999"
      title-active-color="#000000"
    >
      <van-tab
        v-for="(item, index) in tablist"
        :title="item.title"
        :key="index"
        ref="vantab"
      >
        <list :chanal="item" />
      </van-tab>
    </van-tabs>
  </div>
</template>

<script>
import list from "../components/list.vue";

export default {
  components: {
    list
  },
  data() {
    return {
      tablist: [
        { title: "当前活动", type: "online" },
        { title: "往期活动", type: "expire" }
      ],
      active: 0
    };
  },

  methods: {}
};
</script>

<style lang="scss" scoped>
.container {
  background-color: #f2f2f2;
  min-height: 100vh;
  /deep/.van-tabs__nav {
    width: 100%;
    display: flex;
    justify-content: flex-start;
  }
  /deep/.van-tab {
    //  width: 25%;
    padding: 0 0;
    display: flex;
    flex: unset;
    margin-left: 28px;
    margin-right: 56px;
    font-size: 30px;
    z-index: 99;
  }
  /deep/.van-tab--active {
    font-weight: 600;
  }
  /deep/.van-tabs__line {
    background: linear-gradient(-90deg, #ffeda3 0%, #fac33a 100%);
    position: absolute;
    bottom: 0.75rem;
  }
}
</style>
